import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { t } from "@lingui/macro";
import { toast } from "react-toastify";
import ReactFlow, { Background, BackgroundVariant, ControlButton, Controls, MiniMap, useEdgesState, useNodesState } from "reactflow";
import { Segment } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { usePatchNodeMutation, useMoveNodeMutation } from "../hierarchyService";
import { toast_options_err } from "modules/notification/notificationMiddleware";

import { remapForReactFlow } from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBorderAll, faBorderNone } from "@fortawesome/free-solid-svg-icons";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

import GenericNode from "./nodes/GenericNode";
import ContextMenu from "./ContextMenu";
import UpdateNode from "./nodes/UpdateNode";

const customNodeTypes = {
    equipmentLink: GenericNode,
    equipmentUnlink: GenericNode
};

const DiagramFlow = (props) => {
    const { nodesInfo, org, diagram, disabled_diagram, rangeTime, equipments } = props;
    const [nodes, setNodes, onNodesChange] = useNodesState(nodesInfo?.nodes_edges?.nodes ?? []);
    const [edges, setEdges, onEdgesChange] = useEdgesState(nodesInfo?.nodes_edges?.edges ?? []);
    const [displayBackground, setDisplayBackground] = useState(false);
    const [initialPosition, setInitialPosition] = useState(null); //use when drag&drop node
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [menu, setMenu] = useState(null);
    const ref = useRef(null);

    //Define semantic equipment options
    const eqptsOptions = useMemo(() => {
        const filter_eqpts = _.chain(equipments)
            .reduce((res, eqpt) => {
                if (eqpt?.site !== diagram.site) {
                    return res;
                }
                res.push(eqpt);
                return res;
            }, [])
            .value();

        return [{ key: -1, value: -1, text: i18n._(t`No equipment`) }].concat(filter_eqpts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [diagram, equipments, i18n]);

    const [updatePosition, updatePos] = usePatchNodeMutation();
    const [move, moveNode] = useMoveNodeMutation();

    useEffect(() => {
        if (updatePos.isError || moveNode.isError) {
            let error = i18n._(t`cannot change node position`);
            if (updatePos.error?.data && !_.includes(updatePos.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={updatePos.error?.data} />;
            }
            if (moveNode.error?.data && !_.includes(moveNode.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={moveNode.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [updatePos, moveNode]);

    const onNodeContextMenu = useCallback(
        (e, node) => {
            if (!disabled_diagram) {
                // Prevent native context menu from showing
                e.preventDefault();
                setMenu({
                    node: node,
                    top: e.clientY,
                    left: e.clientX,
                    rangeTime,
                    diagram,
                    setMenu: setMenu
                });
                setNodes((nds) => {
                    return _.map(nds, (all_node) => {
                        if (all_node.id === node.id) {
                            return { ...all_node, selected: true };
                        }
                        return { ...all_node, selected: false };
                    });
                });
            }
        },
        [diagram, disabled_diagram, rangeTime, setMenu, setNodes]
    );

    // Close the context menu if it's open whenever the window is clicked.
    const onPaneClick = useCallback(() => setMenu(null), [setMenu]);

    return (
        <Segment style={{ height: "800px" }} attached>
            <ReactFlow
                ref={ref}
                fitView
                snapToGrid={true}
                snapGrid={[30, 30]}
                disableKeyboardA11y={true}
                selectionKeyCode={null} //prevent area selection
                multiSelectionKeyCode={null} //prevent multiple selection during DragNDrop
                nodeTypes={customNodeTypes}
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeDragStart={(event, node) => {
                    const { position } = node;
                    setInitialPosition(position);
                    setMenu(null);
                }}
                onNodeDragStop={async (event, node) => {
                    const sameNodePosition = _.isEqual(node.position, initialPosition);
                    if (sameNodePosition) {
                        return setInitialPosition(null);
                    }
                    const action = await updatePosition({
                        org,
                        data: { position_x: node.position.x, position_y: node.position.y },
                        node_id: parseInt(node?.data?.node_db?.id ?? 0)
                    });
                    const { error = null, data = null } = action;
                    setNodes((nds) => {
                        return _.map(nds, (all_node) => {
                            if (all_node.id === node.id) {
                                if (error) {
                                    return { ...all_node, position: initialPosition, positionAbsolute: initialPosition };
                                } else {
                                    return {
                                        ...all_node,
                                        data: {
                                            node_db: {
                                                ...data,
                                                //put old value of percentage && consumption
                                                //because 'null' in 'data' response from server
                                                percentage: all_node.data.node_db.percentage,
                                                consumption: all_node.data.node_db.consumption
                                            }
                                        }
                                    };
                                }
                            }
                            return all_node;
                        });
                    });
                    setInitialPosition(null);
                }}
                onConnect={async (connection) => {
                    const { source, target } = connection;
                    if (_.includes(source, target)) return;
                    const node_to_move = _.find(nodes, (node) => node.id === target);
                    if (node_to_move) {
                        const init_parent = node_to_move?.data?.node_db?.parent;
                        if (init_parent === source) return;
                        const action = await move({
                            diagram_id: diagram.id,
                            org,
                            start: rangeTime.start.format("YYYY-MM-DD"),
                            end: rangeTime.end.format("YYYY-MM-DD"),
                            data: { parent: source, node: target }
                        });
                        const error = _.get(action, "error", null);
                        if (!error) {
                            const nodes_edges = _.reduce(
                                _.uniqBy(action.data, "id"),
                                (res, node) => {
                                    const { node: remapNode, edge } = remapForReactFlow(node);
                                    if (edge) {
                                        res.edges.push(edge);
                                    }
                                    res.nodes.push(remapNode);

                                    return res;
                                },
                                { nodes: [], edges: [] }
                            );
                            await setNodes((nds) => {
                                const nodes_l = [...nds];
                                _.each(nodes_edges.nodes, (node) => {
                                    const old_node_index = _.findIndex(nodes_l, { id: node.id });
                                    if (old_node_index !== -1) {
                                        nodes_l[old_node_index] = {
                                            ...nodes_l[old_node_index],
                                            type: node.type,
                                            data: {
                                                ...nodes_l[old_node_index].data,
                                                node_db: node.data.node_db
                                            }
                                        };
                                    }
                                    if (old_node_index === -1) {
                                        //new node children add after move
                                        nodes_l.push({
                                            ...node,
                                            data: {
                                                ...node.data
                                            }
                                        });
                                    }
                                });

                                //Here we clean nodes to remove old children of move_node
                                const clean_nodes = _.filter(nodes_l, (item) => {
                                    return !_.includes(item.id, target);
                                });
                                return clean_nodes;
                            });

                            await setEdges((eds) => {
                                const all_edges = [...eds, ...nodes_edges.edges];
                                const clean_edges = _.chain(all_edges)
                                    .uniqBy("id") //fusion between old && new nodes
                                    .filter((item) => {
                                        if (_.includes(item.id, target)) {
                                            //remove all edges with link to move_node
                                            return false;
                                        }
                                        return true;
                                    })
                                    .value();
                                return clean_edges;
                            });
                        }
                    }
                }}
                onConnectStart={async (event, params) => {
                    const { nodeId } = params;
                    await setNodes((nds) => {
                        const nodes_l = [...nds];
                        return _.map(nodes_l, (node) => {
                            if (node.id !== nodeId && _.includes(node.id, nodeId)) {
                                return {
                                    ...node,
                                    style: {
                                        ...node.style,
                                        border: "2px solid var(--foundational-primary)",
                                        borderRadius: "5px",
                                        boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)"
                                    }
                                };
                            }
                            return node;
                        });
                    });
                }}
                onConnectEnd={async (event) => {
                    await setNodes((nds) => {
                        const nodes_l = [...nds];
                        return _.map(nodes_l, (node) => {
                            return {
                                ...node,
                                style: _.omit(node.style, ["border", "borderRadius", "boxShadow"])
                            };
                        });
                    });
                }}
                onNodeDoubleClick={(event, node) => {
                    if (!disabled_diagram) {
                        setOpenModalUpdate(node.data.node_db);
                    }
                }}
                //Prevent usage of flow for non-owner
                nodesDraggable={!disabled_diagram}
                nodesConnectable={!disabled_diagram}
                onPaneClick={onPaneClick}
                onNodeContextMenu={onNodeContextMenu}
                proOptions={{
                    hideAttribution: true
                }}
            >
                {menu && <ContextMenu onClick={onPaneClick} {...menu} />}
                <MiniMap zoomable pannable position="top-right" />
                <Background gap={30} variant={displayBackground ? BackgroundVariant.Dots : null} />
                <Controls position="top-left" showInteractive={false}>
                    {!disabled_diagram && (
                        <ControlButton
                            onClick={() => {
                                setDisplayBackground(!displayBackground);
                            }}
                            title="display background"
                        >
                            <div>
                                {!displayBackground && <FontAwesomeIcon icon={faBorderAll} className="icon" />}
                                {displayBackground && <FontAwesomeIcon icon={faBorderNone} className="icon" />}
                            </div>
                        </ControlButton>
                    )}
                </Controls>
            </ReactFlow>
            {openModalUpdate && (
                <UpdateNode
                    openModal={true}
                    setOpenModal={setOpenModalUpdate}
                    i18n={i18n}
                    node={openModalUpdate}
                    rangeTime={rangeTime}
                    setMenu={setMenu}
                    org={org}
                    eqptsOptions={eqptsOptions}
                />
            )}
        </Segment>
    );
};

export default DiagramFlow;
