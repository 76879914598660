import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Trans } from "@lingui/macro";
import { Grid, Header, Segment } from "semantic-ui-react";

import { getOverviewConsumption, setGlobalFilter } from "modules/globalview/globalViewSlice";

import Back from "modules/common/components/back";
import GlobalViewFilter, { aggregate_options } from "./GlobalViewFilter";
import GraphicIntensive from "./GraphicIntensive";
import GraphicConsumption from "./GraphicConsumption";
import Synthesis from "./Synthesis";
import RadialZone from "./RadialZone";
import RadialUsage from "./RadialUsage";

const GlobalView = (props) => {
    const { default_time, sites, usages } = props;
    const dispatch = useDispatch();
    const { org, unit, globalview } = useSelector((state) => state);

    const first_site = _.chain(sites).head().get("value").value();
    const first_unit = _.chain(unit.units)
        .orderBy("key")
        .filter((record) => _.includes(["kWh", "m³", "Nm³"], record.text))
        .head()
        .get("value")
        .value();

    const default_global_filter = {
        time: default_time,
        compare_time: default_time,
        todo_compare: false,
        site: [first_site],
        unit: first_unit, //Unit could be Number or String
        aggregate: _.chain(aggregate_options).head().get("value").value(),
        aggregate_type: null,
        calendar_type: [],
        usages: [],
        tags: [],
        zones: []
    };

    useEffect(() => {
        return () => {
            setGlobalFilter(null);
        };
    }, []);

    // Retrieve intensive for temperature, Co2, hygrometry, pm25
    // Used to display all intensive graphic panel
    const intensive_unit_list = _.chain(unit.units)
        .filter((unit) => _.includes(["°C", "µg/m3", "ppm", "%"], unit.symbol))
        .value();

    useEffect(() => {
        (async () => {
            if (globalview.global_filter) {
                //Check global_filter unit to know which request must be executed. (See GlobalFilter Component for unit definition)
                if (_.get(globalview, "global_filter.unit", null) > 0) {
                    await dispatch(
                        getOverviewConsumption({
                            org: org.current,
                            ...globalview.global_filter,
                            time: { start: moment(globalview.global_filter.time.start), end: moment(globalview.global_filter.time.end) },
                            compare_time: {
                                start: moment(globalview.global_filter.compare_time.start),
                                end: moment(globalview.global_filter.compare_time.end)
                            },
                            unit: _.find(unit.units, { id: globalview.global_filter.unit }),
                            filter: null,
                            sites,
                            usages_list: usages
                        })
                    );
                } else if (_.get(globalview, "global_filter.unit", null) === "kgCo2e") {
                    await dispatch(
                        getOverviewConsumption({
                            org: org.current,
                            ...globalview.global_filter,
                            time: { start: moment(globalview.global_filter.time.start), end: moment(globalview.global_filter.time.end) },
                            compare_time: {
                                start: moment(globalview.global_filter.compare_time.start),
                                end: moment(globalview.global_filter.compare_time.end)
                            },
                            filter: "co2",
                            sites,
                            usages_list: usages
                        })
                    );
                } else if (_.get(globalview, "global_filter.unit", null) !== -1) {
                    // -1 prevent getConsumption for intensive case
                    await dispatch(
                        getOverviewConsumption({
                            org: org.current,
                            ...globalview.global_filter,
                            time: { start: moment(globalview.global_filter.time.start), end: moment(globalview.global_filter.time.end) },
                            compare_time: {
                                start: moment(globalview.global_filter.compare_time.start),
                                end: moment(globalview.global_filter.compare_time.end)
                            },
                            filter: "cost",
                            sites,
                            usages_list: usages
                        })
                    );
                }
            } else {
                // Default is consumption overview with kWh
                await dispatch(
                    getOverviewConsumption({
                        org: org.current,
                        ...default_global_filter,
                        unit: _.find(unit.units, { id: default_global_filter.unit }),
                        filter: null,
                        sites,
                        usages_list: usages
                    })
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalview.global_filter]);

    return (
        <Segment attached>
            <Grid centered verticalAlign="top">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>globalview</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <GlobalViewFilter default_global_filter={default_global_filter} sites={sites} usages={usages} />
                {_.get(globalview, "global_filter.unit", null) === -1 && (
                    <Grid.Row>
                        {_.map(intensive_unit_list, (item, idx) => {
                            return (
                                <Grid.Column key={idx} mobile={16} tablet={7} computer={7} textAlign="center">
                                    <Segment basic>
                                        <GraphicIntensive unit={item} />
                                    </Segment>
                                </Grid.Column>
                            );
                        })}
                    </Grid.Row>
                )}
                {_.get(globalview, "global_filter.unit", null) !== -1 && (
                    <>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={14} textAlign="center">
                                <GraphicConsumption default_global_filter={default_global_filter} sites={sites} usages={usages} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={14} textAlign="center">
                                <Synthesis default_global_filter={default_global_filter} sites={sites} usages={usages} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column mobile={16} tablet={16} computer={7} textAlign="center">
                                <RadialZone />
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={7} textAlign="center">
                                <RadialUsage />
                            </Grid.Column>
                        </Grid.Row>
                    </>
                )}
            </Grid>
        </Segment>
    );
};

export default GlobalView;
