import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Grid, Segment, Header } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { useGetSitesQuery } from "modules/site/siteService";
import { useGetSensorsQuery } from "modules/sensor/sensorService";
import { useGetSensortypesQuery } from "modules/sensor/sensorTypeService";

import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import Sensors from "modules/sensor/components/Sensors";

const PreloadSensors = (props) => {
    const { org } = useSelector((state) => state);

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const sensors = useGetSensorsQuery({ org: org.current }, { skip: !org.current });
    const sensortypes = useGetSensortypesQuery({ org: org.current }, { skip: !org.current });

    if (_.some([sensors.isError, sites.isError, sensortypes.isError])) {
        return (
            <Segment attached>
                <Grid centered verticalAlign="middle">
                    <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                        <Grid.Column width={2}>
                            <Back />
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="center">
                            <Header as="h1">
                                <Trans>sensors management</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    } else if (_.every([sites.isSuccess, sensors.isSuccess, sensortypes.isSuccess])) {
        return <Sensors sites={sites.data} />;
    } else {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />;
    }
};

export default PreloadSensors;
