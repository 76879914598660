import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { Header, Segment, Button, Icon } from "semantic-ui-react";
import { Hint, DiscreteColorLegend } from "react-vis";

import i18n from "modules/i18n/i18nConfig";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RotatedLabelRadialChart from "modules/common/components/graphic/PieChart";
import { Media } from "App";

const RadialZone = (props) => {
    const [hint, setHint] = useState(null);
    const [displaySeries, setDisplaySeries] = useState(false);
    const { globalview } = useSelector((state) => state);
    const [series, setSeries] = useState([]);
    const state = _.get(globalview, "radialZone", null);

    useEffect(() => {
        if (state.status === "succeeded") {
            setSeries(
                _.map(state.data, (serie) => {
                    return { ...serie, title: i18n._(serie.title) };
                })
            );
        }
    }, [state]);

    const hideShowSeries = (hide) => {
        const update_series = _.chain(series)
            .reduce((res, serie, idx) => {
                if (hide) {
                    if (idx === 0) {
                        res.push({ ...serie, disabled: false });
                    } else {
                        res.push({ ...serie, disabled: true });
                    }
                } else {
                    res.push({ ...serie, disabled: false });
                }
                return res;
            }, [])
            .value();

        setSeries(update_series);
    };

    const clickHandler = (serie_clk) => {
        const other_disabled = _.chain(series)
            .reject({ id: serie_clk?.id })
            .every((serie) => {
                return serie.disabled === true;
            })
            .value();

        const update_series = _.chain(series)
            .map((item) => {
                if (item.id === _.get(serie_clk, "id")) {
                    const update_serie = { ...item, disabled: other_disabled ? false : !item.disabled };
                    return update_serie;
                } else {
                    return item;
                }
            })
            .value();

        setSeries(update_series);
    };

    return (
        <>
            <Header as="h3" block textAlign="center" attached="top">
                <Trans>weight consumption by area</Trans>
            </Header>
            {_.get(state, "status") === "loading" && (
                <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
            )}
            {_.get(state, "status") === "failed" && (
                <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
            )}
            {_.get(state, "status") === "succeeded" && _.size(state.data) === 0 && (
                <MessageDisplay message={i18n._(t`no data`)} level="warning" iconName="warning circle" isLoading={false} />
            )}
            {_.get(state, "status") === "succeeded" && _.size(state.data) > 0 && (
                <>
                    <Media greaterThanOrEqual="computer">
                        {(mediaClassNames, renderChildren) =>
                            renderChildren && (
                                <Segment attached textAlign="right">
                                    <Button
                                        icon
                                        labelPosition="right"
                                        onClick={async (event, data) => {
                                            await setDisplaySeries(!displaySeries);
                                            hideShowSeries(!displaySeries);
                                        }}
                                        className="no-print"
                                    >
                                        {displaySeries ? i18n._(t`display series`) : i18n._(t`hide series`)}
                                        <Icon name={displaySeries ? "eye" : "eye slash"} />
                                    </Button>
                                </Segment>
                            )
                        }
                    </Media>
                    <Segment attached className="pwaGlobalRadial">
                        <RotatedLabelRadialChart
                            colorType="literal"
                            className="pwaGlobalviewRadialChart"
                            data={_.reject(series, { disabled: true })}
                            onValueMouseOver={(v) => setHint(v)}
                            onSeriesMouseOut={(v) => setHint(null)}
                            radius={120}
                            labelsRadiusMultiplier={1.2}
                            labelsStyle={{ fontSize: 12 }}
                            labelsRotation={-10}
                            showLabels
                            style={{ stroke: "#fff", strokeWidth: 2 }}
                            width={300}
                            height={300}
                            margin={{ top: 0, bottom: 0 }}
                            getLabel={(d) => {
                                if (_.isFinite(d.percent) && d.percent > 1) {
                                    return `${_.isFinite(d.percent) ? i18n.number(d.percent, { maximumFractionDigits: 2 }) : "-"}%`;
                                } else {
                                    return "...";
                                }
                            }}
                        >
                            {hint !== null && (
                                <Hint value={hint}>
                                    <Header as="h4" attached="top" block>
                                        <svg height="10" width="14" style={{ marginRight: "5px" }}>
                                            <rect height="10" width="14" style={{ fill: hint.color }} />
                                        </svg>
                                        {`${hint.zone || "-"}`}
                                    </Header>
                                    <Header as="h4" attached>{`${hint.percent} %`}</Header>
                                    <Header as="h5" attached block textAlign="right">
                                        <i>{`${hint.site || "-"}`}</i>
                                    </Header>
                                </Hint>
                            )}
                        </RotatedLabelRadialChart>
                        <Media lessThan="tablet">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <DiscreteColorLegend
                                        orientation={"horizontal"}
                                        items={series}
                                        onItemClick={clickHandler}
                                        className="pwaGlobalViewRadialLegend"
                                    />
                                )
                            }
                        </Media>
                        <Media greaterThanOrEqual="tablet">
                            {(mediaClassNames, renderChildren) =>
                                renderChildren && (
                                    <DiscreteColorLegend
                                        orientation={"vertical"}
                                        items={series}
                                        onItemClick={clickHandler}
                                        className="pwaGlobalViewRadialLegend"
                                    />
                                )
                            }
                        </Media>
                    </Segment>
                </>
            )}
        </>
    );
};

export default RadialZone;
