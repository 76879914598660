import React, { useState, useEffect } from "react";
import _ from "lodash";
import { t, Trans } from "@lingui/macro";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Segment, Header, Input, Button, Icon, Popup, Label } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import history_app from "history_app";
import { useGetSitesQuery } from "../siteService";
import { useGetZonesQuery } from "modules/area/areaService";
import { setSearchNameFilter, resetFilterWithPage, setItemsPerPage, setPage, setStickPage } from "../siteSlice";
import { checkRights } from "modules/auth/utils";
import { checkStringInclude } from "modules/common/utils";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DeleteSite from "./DeleteSite";

const MaxDefaultDisplay = 2;

const Sites = () => {
    const [localSearchName, setLocalSearchName] = useState("");

    const dispatch = useDispatch();
    const { site, org, auth, notification } = useSelector((state) => state);
    const [showMore, setShowMore] = useState({});

    const sites = useGetSitesQuery({ org: org.current }, { skip: !org.current });
    const zones = useGetZonesQuery({ org: org.current }, { skip: !org.current });
    const user_rights = auth.rights;

    useEffect(() => {
        (async () => {
            await setLocalSearchName(site.filter.searchName);
            await dispatch(setSearchNameFilter(site.filter.searchName));
            //Change stick page after filter application.
            site.pagination.stickPage && (await dispatch(setStickPage(false)));
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "name", label: i18n._(t`name`), textAlign: "left" },
        { id: "zones", label: i18n._(t`Areas`), textAlign: "left" },
        { id: "description", label: i18n._(t`description`), textAlign: "left" },
        {
            id: "actions",
            label: i18n._(t`actions`),
            textAlign: "left",
            ordering: false,
            collapsing: true
        }
    ];

    const renderZones = (zones_name, site_id) => {
        if (_.size(zones_name) === 0) {
            return (
                <Label>
                    <Trans>No area in this site</Trans>
                </Label>
            );
        }
        const displayShowMore = _.size(zones_name) > MaxDefaultDisplay;

        return (
            <Label.Group>
                {_.map(zones_name, (name, idx) => {
                    if (!showMore[site_id] && idx < MaxDefaultDisplay) {
                        return (
                            <Label color="teal" circular basic key={idx}>
                                {name}
                            </Label>
                        );
                    } else if (showMore[site_id]) {
                        return (
                            <Label color="teal" circular basic key={idx}>
                                {name}
                            </Label>
                        );
                    }
                    return null;
                })}
                {displayShowMore && (
                    <Icon
                        style={{ cursor: "pointer" }}
                        size={"large"}
                        color="grey"
                        name={`arrow alternate circle ${showMore[site_id] ? "left" : "right"} outline`}
                        onClick={() => setShowMore({ [site_id]: !showMore[site_id] })}
                    />
                )}
            </Label.Group>
        );
    };

    const sites_list = _.chain(sites.data)
        .reduce((res, item) => {
            if (site.filter.searchName === "") {
                res.push(item);
            } else if (checkStringInclude(site.filter.searchName, item.name)) {
                res.push(item);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "pre" };
            const rights = checkRights(item, user_rights, true);
            const site_zones = _.chain(item)
                .get("zones", [])
                .reduce((res, zone_id) => {
                    const zone = _.find(zones.data, { id: zone_id });
                    if (zone) res.push(zone.name);
                    return res;
                }, [])
                .value();
            const site_zones_val = _.chain(site_zones).join(",").value();

            const can_change = notification.srv_status.db_status === "rw" && _.includes(rights, "can_change");
            const can_delete = notification.srv_status.db_status === "rw" && _.includes(rights, "can_delete");

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                name: {
                    render: <span style={customStyle}>{_.get(item, "name", "-")}</span>,
                    value: _.get(item, "name", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                description: {
                    render: <span style={customStyle}>{_.get(item, "description", "-")}</span>,
                    value: _.get(item, "description", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                zones: {
                    render: renderZones(site_zones, item.id),
                    value: site_zones_val,
                    textAlign: "left",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Popup
                                    trigger={
                                        <Button
                                            icon={can_change ? "edit" : "eye"}
                                            onClick={async (e) => {
                                                await dispatch(setStickPage(true));
                                                if (can_change) {
                                                    history_app.push(`/sites/${item.id}/change`);
                                                } else {
                                                    history_app.push(`/sites/${item.id}/view`);
                                                }
                                            }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        {can_change && <Trans>Site edition</Trans>}
                                        {!can_change && <Trans>View site</Trans>}
                                    </Popup.Content>
                                </Popup>
                                {can_delete && <DeleteSite item={item} />}
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                {/* Header  */}
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target="/manage" />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Sites</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>

                {/* check sites status */}
                {sites.isError && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}
                {sites.isFetching && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    </Grid.Row>
                )}

                {/* Main Content  */}
                {sites.isSuccess && (
                    <Grid.Row>
                        <Grid.Column width={15}>
                            <Grid celled>
                                {/* Search input  */}
                                <Grid.Column mobile={16} tablet={3} computer={3}>
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder={i18n._(t`search site`)}
                                        onChange={(e, { value }) => {
                                            setLocalSearchName(value);
                                            dispatch(setSearchNameFilter(value));
                                        }}
                                        value={localSearchName}
                                    />
                                </Grid.Column>
                                {notification.srv_status.db_status === "rw" && user_rights.is_admin && (
                                    <Grid.Column width={16}>
                                        <Button
                                            icon
                                            labelPosition="left"
                                            onClick={async (e) => {
                                                await dispatch(resetFilterWithPage());
                                                history_app.push(`sites/add`);
                                            }}
                                        >
                                            <Icon name="add" />
                                            <Trans>add a new site</Trans>
                                        </Button>
                                    </Grid.Column>
                                )}

                                {/* Table  */}
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={sites_list}
                                        order="asc"
                                        orderBy="name"
                                        page={site.pagination.page}
                                        rowsPerPage={site.pagination.itemsPerPage}
                                        setPage={(page) => {
                                            dispatch(setPage(page));
                                        }}
                                        setItemsPerPage={(items) => {
                                            dispatch(setItemsPerPage(items));
                                        }}
                                        textItemPerPages={i18n._(t`items per page`)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                )}
            </Grid>
        </Segment>
    );
};

export default Sites;
