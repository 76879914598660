import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { t, Trans } from "@lingui/macro";

import i18n from "modules/i18n/i18nConfig";
import { setPage, setItemsPerPage, resetFilterWithPage } from "../alerteventSlice";
import { checkStringInclude, removeAccents } from "modules/common/utils";
import { useGetTriggeredAlertsQuery } from "../alerteventService";
import { useGetMeasurementsQuery } from "modules/measurement/measurementService";
import { useGetDataflowsQuery } from "modules/dataflow/dataflowService";
import { useGetMeasurementtypesQuery } from "modules/measurement/measurementtypeService";
import { useGetEquipmentsQuery } from "modules/equipment/equipmentService";
import { useGetUnitsQuery } from "modules/unit/unitService";
import history_app from "history_app";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";
import { Button, Card, Grid, Icon, Input, Label, Popup, Segment, Dropdown } from "semantic-ui-react";

const customStyle = { cursor: "default", whiteSpace: "pre" };

const formatDateItem = (date_str, current_lng) => {
    if (!moment(date_str).isValid()) {
        return "-";
    }
    return `${moment(date_str).locale(current_lng).format("L")} ${moment(date_str).locale(current_lng).format("LTS")}`;
};

const renderDate = (date_str, current_lng, color) => {
    if (date_str === null) return "-";
    if (color === undefined) return formatDateItem(date_str, current_lng);
    return <Label color={color}>{formatDateItem(date_str, current_lng)}</Label>;
};

const PaneAlerts = (props) => {
    const { rangeTime } = props;
    const dispatch = useDispatch();
    const [localSearchName, setLocalSearchName] = useState("");
    const [localOrderBy, setLocalOrderBy] = useState(-1);
    const [localOrder, setLocalOrder] = useState("desc");

    const { org } = useSelector((state) => state);
    const pagination = useSelector((state) => state.alertevent.pagination);
    const current_lng = useSelector((state) => state.i18n.current); //force refresh for lng

    const triggeredalerts = useGetTriggeredAlertsQuery(
        { org: org.current, start: rangeTime.start.toISOString(), end: rangeTime.end.toISOString() },
        { skip: !org.current }
    );
    const measurements = useGetMeasurementsQuery({ org: org.current }, { skip: !org.current });
    const dataflows = useGetDataflowsQuery({ org: org.current }, { skip: !org.current });
    const measurementtypes = useGetMeasurementtypesQuery({ org: org.current }, { skip: !org.current });
    const equipments = useGetEquipmentsQuery({ org: org.current }, { skip: !org.current });
    const units = useGetUnitsQuery({ org: org.current }, { skip: !org.current });

    useEffect(() => {
        dispatch(setPage(1));
    }, [dispatch, rangeTime]);

    const orderOptions = [
        { key: -1, value: -1, text: t`last_activity` },
        { key: 1, value: 1, text: t`name` },
        { key: 2, value: 2, text: t`Start of alert` },
        { key: 8, value: 8, text: t`End of alert` },
        { key: 3, value: 3, text: t`Notification date` },
        { key: 9, value: 9, text: t`End of alert notification date` }
    ];

    const headCells = [
        { id: "id", label: "id", textAlign: "right", ordering: false },
        { id: "name", label: i18n._(t`name`), textAlign: "left", ordering: false },
        { id: "start_change_state", label: i18n._(t`Start of alert`), textAlign: "left", ordering: false },
        { id: "display_condition_start", label: i18n._(t`triggered_reason`), textAlign: "left", ordering: false },
        { id: "end_change_state", label: i18n._(t`End of alert`), textAlign: "left", ordering: false },
        { id: "display_condition_end", label: i18n._(t`Return value to normal`), textAlign: "left", ordering: false },
        { id: "notified_date_start", label: i18n._(t`Notification date`), textAlign: "left", ordering: false },
        { id: "notified_date_end", label: i18n._(t`End of alert notification date`), textAlign: "left", ordering: false }
    ];

    const renderCondition = useCallback(
        (condition, title, triggered_values) => {
            if (equipments.isSuccess && units.isSuccess && dataflows.isSuccess && measurements.isSuccess && measurementtypes.isSuccess) {
                if (condition === null) return "-";
                return (
                    <Popup
                        trigger={
                            <div style={{ zIndex: 10 }}>
                                <span style={customStyle}>{condition || "-"}</span>
                            </div>
                        }
                        style={{ padding: 0 }}
                    >
                        <Popup.Content>
                            <Card style={{ width: "100%" }}>
                                <Card.Content>
                                    <Card.Header>{i18n._(title)}</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    {_.chain(triggered_values)
                                        .map((value, triggered_key) => {
                                            const [measure_id, depth = null] = _.split(triggered_key, "/");
                                            const mes = _.find(measurements.data, { id: Number(measure_id) });
                                            const df = _.find(dataflows.data, { id: mes?.dataflow });
                                            const equipment = _.find(equipments.data, { id: df?.equipment });
                                            const mt_type = _.find(measurementtypes.data, { id: mes?.measurementtype });
                                            const display_unit = _.find(units.data, { id: mes?.display_unit || mt_type?.unit });
                                            const trig_val = _.isFinite(value) ? i18n.number(value, { maximumFractionDigits: 2 }) : "-";
                                            const minutes = Math.floor(parseFloat(depth) / 60);
                                            return (
                                                <Segment
                                                    basic
                                                    key={measure_id}
                                                    style={{ ...customStyle, overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    <span>"{equipment?.name ?? "-"}"</span>
                                                    {"\u00A0"}={"\u00A0"}
                                                    <span>{trig_val}</span>
                                                    {"\u00A0"}
                                                    <span>{display_unit?.symbol || display_unit?.intensive || "-"}</span>
                                                    {_.isFinite(minutes) && <span>&nbsp;({`${minutes}  ${i18n._(t`min`)}`})</span>}
                                                </Segment>
                                            );
                                        })
                                        .value()}
                                </Card.Content>
                            </Card>
                        </Popup.Content>
                    </Popup>
                );
            }
            return "-";
        },
        // eslint-disable-next-line
        [equipments, units, dataflows, measurements, measurementtypes, current_lng]
    );

    const rows = useMemo(() => {
        const customStyle = { cursor: "default", whiteSpace: "pre" };
        if (triggeredalerts.isSuccess && measurements.isSuccess && measurementtypes.isSuccess && dataflows.isSuccess) {
            return _.chain(triggeredalerts.data)
                .thru(function (data) {
                    if (localOrderBy === -1 && localOrder === "desc") {
                        return [...data];
                    } else if (localOrderBy === -1 && localOrder === "asc") {
                        return _.reverse([...data]);
                    } else {
                        return _.orderBy(
                            [...data],
                            [
                                function (alert) {
                                    const field = alert?.[localOrderBy] ?? null;
                                    if (field === null) return "";
                                    return removeAccents(field).toLowerCase();
                                }
                            ],
                            [localOrder]
                        );
                    }
                })

                .reduce((res, alert, idx) => {
                    if (localSearchName !== "" && !checkStringInclude(localSearchName, alert?.[1])) return res;

                    const start_change_state = alert?.[2] ?? null;
                    const end_change_state = alert?.[8] ?? null;
                    const notified_date_start = alert?.[3] ?? null;
                    const notified_date_end = alert?.[9] ?? null;

                    const display_condition_start = alert?.[7] ?? null;
                    const display_condition_end = alert?.[13] ?? null;

                    res.push({
                        id: {
                            render: null,
                            value: alert?.[0],
                            textAlign: "left",
                            datatype: "number"
                        },
                        name: {
                            render: <span style={customStyle}>{alert?.[1] ?? "-"}</span>,
                            value: alert?.[1] ?? "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        start_change_state: {
                            render: renderDate(start_change_state, current_lng, "red"),
                            value: start_change_state === null ? null : moment(start_change_state),
                            textAlign: "right",
                            datatype: "date"
                        },
                        display_condition_start: {
                            render: renderCondition(display_condition_start, t`detail`, alert?.[4] ?? null), //Add tooltip for measurements detail
                            value: display_condition_start || "-",
                            textAlign: "left",
                            datatype: "string"
                        },
                        end_change_state: {
                            render: renderDate(end_change_state, current_lng, "green"),
                            value: end_change_state === null ? null : moment(end_change_state),
                            textAlign: "right",
                            datatype: "date"
                        },
                        display_condition_end: {
                            render: renderCondition(display_condition_end, t`detail`, alert?.[10] ?? null), //Add tooltip for measurements detail
                            value: display_condition_end || "-",
                            textAlign: "right",
                            datatype: "string"
                        },
                        notified_date_start: {
                            render: renderDate(notified_date_start, current_lng),
                            value: notified_date_start === null ? null : moment(notified_date_start),
                            textAlign: "right",
                            datatype: "date"
                        },
                        notified_date_end: {
                            render: renderDate(notified_date_end, current_lng),
                            value: notified_date_end === null ? null : moment(notified_date_end),
                            textAlign: "right",
                            datatype: "date"
                        }
                    });

                    return res;
                }, [])
                .value();
        }
        return [];
    }, [renderCondition, triggeredalerts, measurements, measurementtypes, dataflows, current_lng, localSearchName, localOrder, localOrderBy]);

    if (triggeredalerts.isError) {
        return <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} attached={false} />;
    }
    if (triggeredalerts.isFetching) {
        return <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} attached={false} />;
    }
    if (triggeredalerts.isSuccess) {
        return (
            <Grid celled>
                <Grid.Column mobile={16} tablet={3} computer={3}>
                    <Input
                        fluid
                        icon="search"
                        placeholder={i18n._(t`search name`)}
                        onChange={(e, { value }) => {
                            setLocalSearchName(value);
                            dispatch(setPage(1));
                        }}
                        value={localSearchName}
                        disabled={_.size(triggeredalerts.data) === 0}
                    />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={3} computer={3}>
                    <Button.Group>
                        <Dropdown
                            style={{
                                // same radius & borders values as Semantic UI
                                borderRadius: ".28571429rem 0rem 0rem .28571429rem",
                                border: "1px solid rgba(34, 36, 38, 0.15)"
                            }}
                            as={Button}
                            options={_.map(orderOptions, ({ key, text, value }) => ({ key, text: i18n._(text), value }))}
                            placeholder={i18n._(t`select order`)}
                            selection
                            onChange={(e, { value }) => {
                                setLocalOrderBy(value);
                            }}
                            value={localOrderBy}
                        />

                        <Button
                            icon={localOrder === "desc" ? "sort amount down" : "sort amount up"}
                            onClick={() => {
                                return localOrder === "asc" ? setLocalOrder("desc") : setLocalOrder("asc");
                            }}
                        />
                    </Button.Group>
                </Grid.Column>

                <Grid.Column width={16}>
                    <Button
                        icon
                        labelPosition="left"
                        onClick={async (e) => {
                            history_app.push(`/alerts`);
                            await dispatch(resetFilterWithPage());
                        }}
                    >
                        <Icon name="bullhorn" />
                        <Trans>alert setup</Trans>
                    </Button>
                </Grid.Column>
                <Grid.Column width={16}>
                    <TableEnhanced
                        page={pagination.page}
                        setPage={(page) => {
                            dispatch(setPage(page));
                        }}
                        setItemsPerPage={(items) => {
                            dispatch(setItemsPerPage(items));
                        }}
                        headCells={headCells}
                        rows={rows}
                        order={null}
                        textItemPerPages={i18n._(t`items per page`)}
                    />
                </Grid.Column>
            </Grid>
        );
    }
    return null;
};

export default React.memo(PaneAlerts);
