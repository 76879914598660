import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import { toast } from "react-toastify";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Grid, Header, Segment, Container, Button, Label, Divider, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { useGetMailingQuery, useGetMailingsQuery, useAddMailingMutation, useUpdateMailingMutation } from "../mainlingService";
import { regexEmail } from "modules/common/utils";
import { toast_options, toast_options_err } from "modules/notification/notificationMiddleware";
import history_app from "history_app";

import { InputAdapter } from "modules/common/components/form";
import Back from "modules/common/components/back";
import MessageDisplay from "modules/common/components/MessageDisplay";
import RequestErrorRender from "modules/common/components/RequestErrorRender";

const Mailing = () => {
    const { id_mailing, mode } = useParams();

    const { org, auth, notification } = useSelector((state) => state);
    const mailing = useGetMailingQuery({ org: org.current, data: id_mailing }, { skip: !org.current || id_mailing === undefined });
    const mailings = useGetMailingsQuery({ org: org.current }, { skip: !org.current });
    const user_email = auth.user.email;

    const [updateMailingList, update] = useUpdateMailingMutation();
    const [createMailingList, create] = useAddMailingMutation();

    // Update mailing list
    useEffect(() => {
        if (update.isFetching) {
            toast.info(i18n._(t`updating mailing list`), toast_options);
        }
        if (update.isSuccess) {
            toast.success(i18n._(t`mailing list updated`), toast_options);
            history_app.push(`/mailings`);
        }
        if (update.isError) {
            let error = i18n._(t`cannot update mailing list`);
            if (update.error?.data && !_.includes(update.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={update.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [update]);

    // Create a new mailing list
    useEffect(() => {
        if (create.isFetching) {
            toast.info(i18n._(t`creating a new mailing list`), toast_options);
        }
        if (create.isSuccess) {
            toast.success(i18n._(t`new mailing list created`), toast_options);
            history_app.push("/mailings");
        }
        if (create.isError) {
            let error = i18n._(t`cannot add new mailing list`);
            if (create.error?.data && !_.includes(create.error?.data, "<!DOCTYPE html>")) {
                error = <RequestErrorRender errors={create.error?.data} />;
            }
            toast(error, { ...toast_options_err, type: "error" });
        }
    }, [create]);

    const initialValues = useMemo(() => {
        const default_mailing = {
            name: "",
            mailinglistmember_set: [{ email: "" }]
        };

        return {
            ...default_mailing,
            ...mailing.data
        };
    }, [mailing.data]);

    const can_change = notification.srv_status.db_status === "rw";

    const handleSubmit = async (data) => {
        if (can_change) {
            if (id_mailing === undefined) {
                createMailingList({ org: org.current, data });
            } else {
                updateMailingList({ org: org.current, data });
            }
        }
    };

    const validate = (values) => {
        const errors = {};

        if (!values.name) {
            errors.name = i18n._(t`name is required`);
        }
        const existing_name = _.chain(mailings.data)
            .filter((mailing) => {
                return mailing.id !== parseInt(id_mailing);
            })
            .find({ name: values.name })
            .value();
        if (existing_name) {
            errors.name = i18n._(t`this name of mailinglist already exists`);
        }

        if (values.mailinglistmember_set.length > 0) {
            errors.mailinglistmember_set = _.map(values.mailinglistmember_set, (item, index) => {
                const mail = _.get(item, "email", null);
                const err = {};
                if (_.includes([undefined, null, ""], mail)) {
                    err.email = <Trans>email is required</Trans>;
                }
                if (!regexEmail.test(mail)) {
                    err.email = <Trans>invalid email format</Trans>;
                }
                const existing = _.findIndex(values.mailinglistmember_set, { email: item.email });
                if (existing >= 0 && existing !== index) {
                    err.email = <Trans>email already exists in this mailing list</Trans>;
                }
                return err;
            });
        }
        return errors;
    };

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back target={"/mailings"} />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            {id_mailing === undefined && <Trans>Add a new mailing list</Trans>}
                            {id_mailing !== undefined && <Trans>Update mailing list</Trans>}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2} />
                </Grid.Row>
                <Grid.Row>
                    {(() => {
                        if (mailing.isError) {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay
                                        message={i18n._(t`error loading data`)}
                                        level="error"
                                        iconName="warning circle"
                                        isLoading={false}
                                        attached={false}
                                    />
                                </Grid.Column>
                            );
                        } else if (_.every(id_mailing === undefined ? mailing.isSuccess : mailing.isSuccess && mailing.data)) {
                            return (
                                <Grid.Column width={15}>
                                    <Container>
                                        <Form
                                            onSubmit={handleSubmit}
                                            initialValues={initialValues}
                                            validate={validate}
                                            keepDirtyOnReinitialize={true}
                                            mutators={{
                                                ...arrayMutators
                                            }}
                                            render={({
                                                handleSubmit,
                                                form: {
                                                    mutators: { push }
                                                },
                                                submitting,
                                                pristine,
                                                invalid,
                                                values
                                            }) => {
                                                return (
                                                    <form onSubmit={handleSubmit} className="ui form">
                                                        <Grid columns={2} doubling>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    <Field
                                                                        name="name"
                                                                        placeholder={i18n._(t`enter name of mailing list`)}
                                                                        label={i18n._(t`name`)}
                                                                        isRequired={true}
                                                                        component={InputAdapter}
                                                                        disabled={mode === "view" || !can_change}
                                                                    />
                                                                </Grid.Column>
                                                                <Grid.Column>
                                                                    <Field
                                                                        name="user"
                                                                        label={i18n._(t`user`)}
                                                                        component={InputAdapter}
                                                                        disabled
                                                                        placeholder={user_email}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                        <Segment>
                                                            <Label attached="top">
                                                                <Trans>members of mailing list</Trans>
                                                            </Label>

                                                            <FieldArray name="mailinglistmember_set">
                                                                {({ fields }) =>
                                                                    fields.map((name, index) => {
                                                                        return (
                                                                            <Segment basic key={name}>
                                                                                <Grid verticalAlign="bottom">
                                                                                    <Grid.Row>
                                                                                        <Grid.Column computer={15} mobile={13}>
                                                                                            <Field
                                                                                                name={`${name}.email`}
                                                                                                placeholder={i18n._(t`enter email of member`)}
                                                                                                type="text"
                                                                                                label={i18n._(t`email`)}
                                                                                                component={InputAdapter}
                                                                                                isRequired={true}
                                                                                                defaultValue={""}
                                                                                                disabled={!can_change}
                                                                                            />
                                                                                        </Grid.Column>
                                                                                        {_.chain(values)
                                                                                            .get("mailinglistmember_set")
                                                                                            .size()
                                                                                            .value() >= 2 && (
                                                                                            <Grid.Column width={1}>
                                                                                                <Button
                                                                                                    type="button"
                                                                                                    icon="trash"
                                                                                                    color="red"
                                                                                                    onClick={() => fields.remove(index)}
                                                                                                    disabled={!can_change}
                                                                                                />
                                                                                            </Grid.Column>
                                                                                        )}
                                                                                    </Grid.Row>
                                                                                </Grid>
                                                                            </Segment>
                                                                        );
                                                                    })
                                                                }
                                                            </FieldArray>
                                                            <Segment basic>
                                                                <Divider />
                                                                <Button
                                                                    disabled={!can_change}
                                                                    positive
                                                                    type="button"
                                                                    onClick={() => push("mailinglistmember_set", { email: "" })}
                                                                    style={{ display: "flex", alignItems: "center", margin: ".3rem" }}
                                                                >
                                                                    <Icon name="plus circle" size="large" />
                                                                    <Trans>add member</Trans>
                                                                </Button>
                                                            </Segment>
                                                        </Segment>
                                                        {can_change && (
                                                            <Button
                                                                type="submit"
                                                                content={i18n._(t`validate`)}
                                                                disabled={submitting || pristine || invalid}
                                                            />
                                                        )}
                                                    </form>
                                                );
                                            }}
                                        />
                                    </Container>
                                </Grid.Column>
                            );
                        } else {
                            return (
                                <Grid.Column width={15}>
                                    <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                                </Grid.Column>
                            );
                        }
                    })()}
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default Mailing;
