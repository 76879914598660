import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Grid, Segment, Header, Popup, Button, Input, Dropdown, Icon } from "semantic-ui-react";
import { t, Trans } from "@lingui/macro";

import history_app from "history_app";
import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";
import { eventTypeOptions } from "../../utils";
import { checkRights } from "modules/auth/utils";
import { useGetMachineEventsQuery, useGetMachinesQuery } from "../../machineService";
import {
    resetEventFilterWithPage,
    setEventItemsPerPage,
    setEventPage,
    setEventSearchNameFilter,
    setEventStickPage,
    setEvtTypeFilter
} from "../../machineSlice";

import Back from "modules/common/components/back";
import TableEnhanced from "modules/common/components/TableEnhanced";
import MessageDisplay from "modules/common/components/MessageDisplay";
import DeleteMachineEvent from "./DeleteMachineEvent";
import moment from "moment";

export const MachineEvents = (props) => {
    const dispatch = useDispatch();
    const { id_machine } = useParams();
    const { org, machine, notification, auth } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const translate = i18n.use(current_lng);
    const [localSearchName, setLocalSearchName] = useState("");

    const machines = useGetMachinesQuery({ org: org.current }, { skip: !org.current || id_machine === undefined });
    const machine_events = useGetMachineEventsQuery({ org: org.current, id_machine }, { skip: !org.current || id_machine === undefined });

    const error_list = [machine_events.isError, machines.isError];
    const loading_list = [machine_events.isFetching, machines.isFetching];
    const status_list = [machine_events.isSuccess, machines.isSuccess];

    useEffect(() => {
        (async () => {
            if (_.every([...status_list])) {
                await setLocalSearchName(machine.event.filter.searchName);
                await dispatch(setEventSearchNameFilter(machine.event.filter.searchName));
                await dispatch(setEvtTypeFilter(machine.event.filter.evtTypeFilter));
                //Change stick page after filter application.
                machine.event.pagination.stickPage && (await dispatch(setEventStickPage(false)));
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, status_list]);

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "title", label: translate._(t`name`), textAlign: "left" },
        { id: "event_date", label: i18n._(t`event_date`), textAlign: "left" },
        { id: "event_type", label: translate._(t`event_type`), textAlign: "left" },
        { id: "declaration_date", label: i18n._(t`declaration_date`), textAlign: "left" },
        { id: "owner_email", label: i18n._(t`owner`), textAlign: "left" },
        { id: "actions", label: translate._(t`actions`), textAlign: "left", ordering: false, collapsing: true }
    ];

    // Check rights based on machine's site
    // Admin or Technician can add machine events
    const current_machine = _.find(machines.data, { id: parseInt(id_machine) });
    const rights = checkRights(current_machine, auth.rights);

    const events_list = _.chain(machine_events)
        .get("data", [])
        .reduce((res, event) => {
            if (machine.event.filter.searchName === "") {
                res.push(event);
            } else if (checkStringInclude(machine.event.filter.searchName, event.title)) {
                res.push(event);
            }
            return res;
        }, [])
        .reduce((res, event) => {
            if (_.size(machine.event.filter.evtTypeFilter) === 0) {
                res.push(event);
            } else if (_.includes(machine.event.filter.evtTypeFilter, event.event_type)) {
                res.push(event);
            }
            return res;
        }, [])
        .reduce((res, item) => {
            const evt_type = _.chain(eventTypeOptions).find({ key: item.event_type }).get("text").value();
            const customStyle = { cursor: "default", whiteSpace: "pre" };

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                title: {
                    render: <span style={customStyle}>{_.get(item, "title", "-")}</span>,
                    value: _.get(item, "title", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                event_date: {
                    render:
                        item.event_date !== null ? (
                            <span style={customStyle}>{`${moment(item.event_date).locale(current_lng).format("L")} ${moment(item.event_date)
                                .locale(current_lng)
                                .format("LT")}`}</span>
                        ) : (
                            "-"
                        ),
                    value: item.event_date !== null ? moment(item.event_date) : null,
                    textAlign: "left",
                    datatype: "date"
                },
                event_type: {
                    render: <span style={customStyle}>{evt_type ? i18n._(evt_type) : "-"}</span>,
                    value: evt_type ? i18n._(evt_type) : null,
                    textAlign: "left",
                    datatype: "string"
                },
                declaration_date: {
                    render:
                        item.declaration_date !== null ? (
                            <span style={customStyle}>{`${moment(item.declaration_date).locale(current_lng).format("L")} ${moment(
                                item.declaration_date
                            )
                                .locale(current_lng)
                                .format("LT")}`}</span>
                        ) : (
                            "-"
                        ),
                    value: item.declaration_date !== null ? moment(item.declaration_date) : null,
                    textAlign: "right",
                    datatype: "date"
                },
                owner_email: {
                    render: <span style={customStyle}>{_.get(item, "owner_email", "-")}</span>,
                    value: _.get(item, "owner_email", "-"),
                    textAlign: "right",
                    datatype: "string"
                },
                actions: {
                    render: (() => (
                        <>
                            <Button.Group>
                                <Popup
                                    trigger={
                                        <Button
                                            icon="eye"
                                            onClick={async (e) => {
                                                await dispatch(setEventStickPage(true));
                                                history_app.push(`events/${item.id}`);
                                            }}
                                        />
                                    }
                                >
                                    <Popup.Content>
                                        <Trans>event view</Trans>
                                    </Popup.Content>
                                </Popup>
                                {false && <DeleteMachineEvent item={item} />}
                            </Button.Group>
                        </>
                    ))(),
                    value: null,
                    textAlign: "right",
                    datatype: null
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Segment attached>
            <Grid centered verticalAlign="middle">
                <Grid.Row stretched verticalAlign="middle" className="pwaModuleHeader">
                    <Grid.Column width={2}>
                        <Back
                            action={async () => {
                                await dispatch(resetEventFilterWithPage());
                            }}
                        />
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="center">
                        <Header as="h1">
                            <Trans>Machine events management</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={15}>
                        {_.some([...loading_list]) && (
                            <MessageDisplay message={i18n._(t`loading data`)} level="info" iconName="circle notched" isLoading={true} />
                        )}
                        {_.some([...error_list]) && (
                            <MessageDisplay message={i18n._(t`error loading data`)} level="error" iconName="warning circle" isLoading={false} />
                        )}
                        {_.every([...status_list]) && (
                            <Grid celled>
                                <Grid.Column mobile={16} tablet={16} computer={3}>
                                    <Input
                                        fluid
                                        icon="search"
                                        placeholder={i18n._(t`search title of event`)}
                                        onChange={(e, { value }) => {
                                            setLocalSearchName(value);
                                            dispatch(setEventSearchNameFilter(value));
                                        }}
                                        value={localSearchName}
                                    />
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={16} computer={3}>
                                    <Dropdown
                                        fluid
                                        options={_.map(eventTypeOptions, ({ key, text, value }) => ({ key, value, text: translate._(text) }))}
                                        placeholder={i18n._(t`select event type`)}
                                        multiple
                                        selection
                                        onChange={(e, { value }) => {
                                            dispatch(setEvtTypeFilter(value));
                                        }}
                                        value={machine.event.filter.evtTypeFilter}
                                    />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    {notification.srv_status.db_status === "rw" && _.includes(rights, "can_change") && (
                                        <Button
                                            icon
                                            labelPosition="left"
                                            onClick={async (e) => {
                                                await dispatch(resetEventFilterWithPage());
                                                history_app.push(`events/add`);
                                            }}
                                        >
                                            <Icon name="add" />
                                            <Trans>add machine event</Trans>
                                        </Button>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <TableEnhanced
                                        headCells={headCells}
                                        rows={events_list}
                                        textItemPerPages={translate._(t`items per page`)}
                                        page={machine.event.pagination.page}
                                        rowsPerPage={machine.event.pagination.itemsPerPage}
                                        setPage={(page) => {
                                            dispatch(setEventPage(page));
                                        }}
                                        setItemsPerPage={(items) => {
                                            dispatch(setEventItemsPerPage(items));
                                        }}
                                        order="desc"
                                        orderBy="event_date"
                                    />
                                </Grid.Column>
                            </Grid>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
};

export default MachineEvents;
