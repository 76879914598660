import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";

import { Grid, Icon } from "semantic-ui-react";

import i18n from "modules/i18n/i18nConfig";
import { checkStringInclude } from "modules/common/utils";
import { useGetLoginHistoryQuery } from "../userService";
import { setPage, setItemsPerPage } from "../loginHistorySlice";

import MessageDisplay from "modules/common/components/MessageDisplay";
import TableEnhanced from "modules/common/components/TableEnhanced";

const LoginHistoryTable = (props) => {
    const { searchEmail, selectedStatus, user_id } = props;

    const dispatch = useDispatch();

    const { org, auth } = useSelector((state) => state);
    const current_lng = useSelector((state) => state.i18n.current);
    const pagination = useSelector((state) => state.loginHistory.pagination);

    const is_admin = auth.rights?.is_admin ?? false;
    const is_my_account = user_id !== undefined;

    const loginHistory = useGetLoginHistoryQuery({ org: org.current }, { skip: !org.current });

    let err_list = [loginHistory.isError];
    let status_list = [loginHistory.isSuccess];

    const headCells = [
        { id: "id", label: "id", textAlign: "right" },
        { id: "email", label: i18n._(t`email`), textAlign: "left" },
        { id: "action", label: i18n._(t`Status`), textAlign: "left" },
        { id: "timestamp", label: i18n._(t`date`), textAlign: "left" }
    ];

    const loginHistory_list = _.chain(loginHistory.data)
        .filter((item) => {
            if (is_my_account) {
                //only user data
                return item.user === user_id;
            } else if (is_admin) {
                //all data for admin (in List of users)
                return true;
            }
            return false;
        })
        .reduce((res, item) => {
            if (searchEmail === "") {
                res.push(item);
            } else if (checkStringInclude(searchEmail, item.user_email)) {
                res.push(item);
            }
            return res;
        }, [])
        .filter((item) => {
            if (selectedStatus === 0) return item;
            return item.action === selectedStatus;
        })
        .reduce((res, item) => {
            const customStyle = { cursor: "default", whiteSpace: "pre" };
            const tmst = moment(item.timestamp, moment.ISO_8601, true);

            res.push({
                id: { render: null, value: item.id, textAlign: "left", datatype: "number" },
                email: {
                    render: <span style={customStyle}>{_.get(item, "user_email", "-")}</span>,
                    value: _.get(item, "user_email", "-"),
                    textAlign: "left",
                    datatype: "string"
                },
                action: {
                    render: (
                        <span style={customStyle}>
                            {item.action === 1 && <Trans>Connected</Trans>}
                            {item.action === 2 && <Trans>Disconnected</Trans>}
                            {item.action === 3 && (
                                <div style={{ fontStyle: "italic", fontWeight: "bold", color: "#FE9A76" }}>
                                    <Icon color="orange" name="ban" />
                                    <Trans>Connection failure</Trans>
                                </div>
                            )}
                        </span>
                    ),
                    value: _.get(item, "action", "-"),
                    textAlign: "left",
                    datatype: null
                },
                timestamp: {
                    render: <span style={customStyle}>{tmst.isValid() ? tmst.locale(current_lng).format("LLL") : "-"}</span>,
                    value: tmst.isValid() ? tmst : null,
                    textAlign: "left",
                    datatype: "date"
                }
            });
            return res;
        }, [])
        .value();

    return (
        <Grid centered verticalAlign="top">
            {(() => {
                if (_.some(err_list)) {
                    return (
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`error loading data`)}
                                level="error"
                                iconName="warning circle"
                                isLoading={false}
                                attached={false}
                            />
                        </Grid.Column>
                    );
                } else if (_.every(status_list)) {
                    return (
                        <Grid.Column width={16}>
                            <TableEnhanced
                                page={pagination.page}
                                setPage={(page) => {
                                    dispatch(setPage(page));
                                }}
                                setItemsPerPage={(items) => {
                                    dispatch(setItemsPerPage(items));
                                }}
                                headCells={headCells}
                                rows={loginHistory_list}
                                textItemPerPages={i18n._(t`items per page`)}
                                orderBy="timestamp"
                                order="desc"
                            />
                        </Grid.Column>
                    );
                } else {
                    return (
                        <Grid.Column width={15}>
                            <MessageDisplay
                                message={i18n._(t`loading data`)}
                                level="info"
                                iconName="circle notched"
                                isLoading={true}
                                attached={false}
                            />
                        </Grid.Column>
                    );
                }
            })()}
        </Grid>
    );
};

export default LoginHistoryTable;
